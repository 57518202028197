<template>
  <div class="page-content">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Report Type</th>
                    <th>Request Date</th>
                    <th>Status</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody v-loading="loading.table">
                  <tr v-for="(item, i) in reportList" :key="i" @click="manage(workspace)" style="cursor: pointer">
                    <td>{{ item.title }}</td>
                    <td>{{ item.type.split('_').map((v) => upperFirst(v)).join(' ') }}</td>
                    <td>{{ formatDate(item.created_at) }}</td>
                    <td>
                      <span style="padding: 6px" class="badge ml-1" :class="item.status_color_str">{{ upperFirst(item.status) }}</span>
                    </td>
                    <td>
                      <a v-if="item.status === 'completed'" target="_blank" :href="item.download_url"><font-awesome icon="download"/> Download</a>
                      <span v-else>-</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <el-empty v-if="totalRow == 0" description="No data"></el-empty>
            </div>
            <b-pagination v-if="totalRow > page_limit" v-model="currentPage" :total-rows="totalRow" :per-page="page_limit"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty, upperFirst, difference } from 'lodash';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import reportApi from '../../../api/reports';
import popupErrorMessages from '../../../library/popup-error-messages';
import config from '../../../config';

export default {
  beforeCreate() {
    this.moment = moment;
    this.humanizeDuration = humanizeDuration;
  },
  name: 'DownloadReports',
  metaInfo: {
    title: 'Download Reports',
  },
  data() {
    return {
      upperFirst,
      showModal: false,
      showModalEdit: false,
      page_limit: 50,
      loading: {
        table: false,
      },
      form: {
        name: '',
        ws_product_id: '',
      },
      dateFilterTypeOptions: [
        {
          value: 'open',
          text: 'Open Date',
        },
        {
          value: 'handling',
          text: 'Handling Date',
        },
        {
          value: 'resolved',
          text: 'Resolved Date',
        },
      ],
      dateFilterType: 'resolved',
      status_filter: ['resolved'],
      statusFilterOptions: [
        {
          value: 'all',
          text: 'All',
        },
        {
          value: 'open',
          text: 'Open',
        },
        {
          value: 'handling',
          text: 'Handling',
        },
        {
          value: 'resolved',
          text: 'Resolved',
        },
      ],
      editedId: '',
      reportList: [],
      loaderStack: 0,
      loader: null,
      search: {
        date_range: [new Date(), new Date()],
      },
      page: 1,
      totalRow: 0,
      date_picker_options: {
        disabledDate: (time) => {
          const today = moment();
          const diff = today.diff(moment(time), 'd');
          if (diff > 90 || diff < 0) {
            return true;
          }
          if (diff === 0) {
            if (today.isSame(moment(time), 'd') === false) {
              return true;
            }
          }
          return false;
        },
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.getList();
      },
    },
  },
  async mounted() {
    this.showLoader();
    await this.getList();
    this.hideLoader();
  },
  methods: {
    countDuration(start, end) {
      return start && end ? humanizeDuration(moment(start).diff(moment(end)), { largest: 2, round: true }) : '-';
    },
    formatDate(string, format = 'DD MMM YYYY, HH:mm') {
      return string ? moment(string).format(format) : '-';
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async downloadReport() {
      this.$confirm(this.$t('report.confirm_download'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            this.loading.table = true;
            if (isEmpty(this.search.date_range)) {
              this.search.date_range = [new Date(), new Date()];
            }
            const [start_date, end_date] = this.search.date_range;
            const response = await reportApi.download({
              workspace_id: this.activeWorkspace._id,
              start_date: this.formatDate(start_date, 'YYYY-MM-DD'),
              end_date: this.formatDate(end_date, 'YYYY-MM-DD'),
              date_filter_type: this.dateFilterType,
              status_filter: this.status_filter,
            }).catch(() => {});
            this.loading.table = false;
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            this.$message({
              title: this.$t('general.success'),
              type: 'success',
              message: this.$t('general.waiting_download'),
              duration: 30 * 1000,
              showClose: true,
            });
          }
          cb();
        },
      });
    },
    async getList() {
      this.loading.table = true;
      const response = await reportApi.downloadList({
        workspace_id: this.activeWorkspace._id,
        page: this.page,
      }).catch(() => {});
      this.loading.table = false;
      await popupErrorMessages(response);
      this.reportList = response.data.rows.map((item) => {
        item.status_color_str = 'badge-danger';
        if (item.status === 'waiting') {
          item.status_color_str = 'badge-warning';
        } else if (item.status === 'completed') {
          item.status_color_str = 'badge-success';
        } else if (item.status === 'failed') {
          item.status_color_str = 'badge-danger';
        }
        item.download_url = `${config.api.baseUrl}/api/downloads/reports/${this.activeWorkspace._id}/${item.id}`;
        return item;
      });
      this.totalRow = response.data.count;
    },
    handleInputStatusFilter(value) {
      if (value[value.length - 1] === 'all') {
        this.status_filter = ['all'];
      } else if (value.indexOf('all') !== -1) {
        this.status_filter.splice(value.indexOf('all'), 1);
      } else if (difference(['open', 'handling', 'resolved'], this.status_filter).length === 0) {
        this.status_filter = ['all'];
      }
      console.log(this.status_filter);
    },
  },
};
</script>
